import axios from 'axios'

const AXIOS = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: 60000,
    withCredentials: true
});

const flatten = (promise) => {
    return promise.then(response => {
        return response.data;
    }).catch(error => {
        return error.response.data
    });
};

export default {
    checkLogin() {
        return flatten(AXIOS.get(`/login`, {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }));
    },
    logout() {
        return flatten(AXIOS.post(`/logout`, null, {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }));
    },
    getUser() {
        return flatten(AXIOS.get(`/user`, {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }));
    },
    listDocuments(after) {
        return flatten(AXIOS.get(`/document?time=${after}`, {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }));
    },
    getDocumentById(id) {
        return flatten(AXIOS.get(`/document/${id}`, {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }));
    },
    getRevisionById(documentId, revisionId) {
        return flatten(AXIOS.get(`/document/${documentId}/revision/${revisionId}`, {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }));
    },
    getPublicDocument(documentId) {
        return flatten(AXIOS.get(`/document/${documentId}/view`, {
            headers: {'Content-Type': 'application/json'}
        }));
    },
    setActiveRevision(documentId, revisionId) {
        return flatten(AXIOS.patch(`/document/${documentId}/revision/${revisionId}/active`, null, {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }));
    },
    createDocument(revisionDTO) {
        return flatten(AXIOS.post(`/document`, revisionDTO.toJson(), {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }));
    },
    createRevision(documentId, revisionDTO) {
        return flatten(AXIOS.post(`/document/${documentId}/revision`, revisionDTO.toJson(), {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }));
    },
    deleteRevision(documentId, revisionId) {
        return flatten(AXIOS.delete(`/document/${documentId}/revision/${revisionId}`, {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }));
    },
    setIsDocumentPublic(documentId, isPublic) {
        return flatten(AXIOS.patch(`/document/${documentId}/public`, isPublic, {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }));
    },
    setDocumentRepo(documentId, repo) {
        return flatten(AXIOS.patch(`/document/${documentId}/repo`, repo, {
            withCredentials: true,
            headers: {'Content-Type': 'plain/text'}
        }));
    },
    createBranding(brandingDTO) {
        return flatten(AXIOS.post(`/branding`, brandingDTO, {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }));
    },
    getBranding(brandingId) {
        return flatten(AXIOS.get(`/branding/${brandingId}`, {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }));
    },
    listBranding() {
        return flatten(AXIOS.get(`/branding`, {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }));
    },
    updateBranding(brandingId, brandingDTO) {
        return flatten(AXIOS.put(`/branding/${brandingId}`, brandingDTO, {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }));
    },
    deleteBranding(brandingId) {
        return flatten(AXIOS.delete(`/branding/${brandingId}`, {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }));
    },
    forceChangelogUpdate(documentId) {
        return flatten(AXIOS.post(`/document/${documentId}/changelog`, null, {
            withCredentials: true,
            headers: {'Content-Type': 'application/json'}
        }));
    },
}