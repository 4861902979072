<template>
  <div>
    <Page ref="panel">
      <div class="columns">
        <div class="column is-3-tablet is-2-fullhd">
          <b-menu :activable="false" v-if="edit">
            <b-menu-list label="Revisions">
              <b-menu-item v-for="rev in revisions" v-bind:key="rev.id"
                           :icon="rev.id === document.currentRevision.id ? 'earth' : 'history'"
                           :active="rev.id === revision.id"
                           @click="showRevision(rev.id)">
                <template #label>
                  {{ new Date(rev.date).toLocaleString() }}
                  <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-right" @click.native.stop>
                    <template #trigger>
                      <b-icon icon="dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item v-if="rev.id !== document.currentRevision.id" @click="setActive(rev.id)">
                      <b-icon icon="earth" size="is-small"/>
                      Set active
                    </b-dropdown-item>
                    <b-dropdown-item class="has-text-danger" @click="deleteRevision(rev.id)">
                      <b-icon icon="delete" size="is-small"/>
                      Delete
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-menu-item>
            </b-menu-list>
          </b-menu>
        </div>
        <div class="column" v-if="revision">
          <b-field grouped>
            <b-field label="Name" expanded>
              <b-input v-model="revision.name"></b-input>
            </b-field>
            <b-field label="Branding" expanded>
              <b-select v-model="revision.branding.id" expanded>
                <option
                    v-for="option in brandings"
                    :value="option.id"
                    :key="option.id">
                  {{ option.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Repo" expanded>
              <b-input v-model="document.repo"></b-input>
            </b-field>
            <div class="button-bar" v-if="edit">
              <b-button @click="clearCache">Clear cache</b-button>
            </div>
            <div class="button-bar">
              <b-button @click="loadPack">Import pack</b-button>
            </div>
            <div class="button-bar" v-if="edit">
              <b-dropdown :value="document.isPublic" aria-role="list">
                <template v-if="document.isPublic" #trigger>
                  <b-button
                      label="Public"
                      type="is-primary"
                      icon-left="earth"
                      icon-right="menu-down"/>
                </template>
                <template v-else #trigger>
                  <b-button
                      label="Hidden"
                      type="is-primary"
                      icon-left="eye-off"
                      icon-right="menu-down"/>
                </template>

                <b-dropdown-item :value="true" @click="setPublic(true)" aria-role="listitem">
                  <div class="media">
                    <b-icon class="media-left" icon="earth"></b-icon>
                    <div class="media-content">
                      <h3>Public</h3>
                      <small>Accessible for anyone with a link</small>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item :value="false" @click="setPublic(false)" aria-role="listitem">
                  <div class="media">
                    <b-icon class="media-left" icon="eye-off"></b-icon>
                    <div class="media-content">
                      <h3>Hidden</h3>
                      <small>Only logged in users can see</small>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="button-bar">
              <b-checkbox-button v-model="preview">Preview</b-checkbox-button>
            </div>
            <div class="button-bar">
              <b-button type="is-primary" @click="save">Save</b-button>
            </div>
          </b-field>
          <div v-show="!preview">
            <DocumentSection v-model="steps" title="Playthrough Guide - Steps">
              <p>List all of the steps required to complete the content. Provide coordinates and/or requirements for
                each
                step and a way skip or return to points.</p>
              <p class="example">Custom map example:</p>
              <ol class="example">
                <li>Go north of spawn to a house with a chest in it</li>
                <li>Collect armor and weapons from chest (10 70 322)</li>
                <li>Follow the road towards the fountain at the center of the town (100 75 200)</li>
                <li>Talk to the old wizard NPC near the fountain</li>
                <li>Accept his quest to defeat the dragon</li>
                <li>Buy a map that leads to the dragon from the cartographer villager in the village</li>
                <li>Follow the map to the dragon’s lair (1200 30 3500)</li>
                <li>Use the weapon from the start of the game to defeat the dragon</li>
                <li>Return to the wizard NPC to finish the quest (100 75 200)</li>
                <li>Continue playing vanilla Minecraft in the rest of the world!</li>
              </ol>
              <p>For survival spawns or maps without expressed goals (comparable to the vanilla gameplay loop) there
                must
                be
                a description of how the players is meant to get the resources needed to complete the vanilla loop
                (including the end portal) and the end portals position in the world. For example, a roleplay map set in
                a
                vanilla world or a map that features an elaborate build meant for vanilla gameplay must have the core
                vanilla Minecraft gameplay loop intact and able to be completed by the player.</p>
              <p>The core steps to complete the game in vanilla are:</p>
              <ol>
                <li>Get stone tools to mine iron</li>
                <li>Get iron tools to mine diamond</li>
                <li>Use diamond pickaxe to collect obsidian</li>
                <li>Make a nether portal with obsidian</li>
                <li>Collect blaze rods from blazes in the nether</li>
                <li>Make blaze powder with blaze rods</li>
                <li>Collect ender pearls from endermen</li>
                <li>Combine blaze powder with ender pearls to create eyes of ender</li>
                <li>Find a stronghold with an end portal in it</li>
                <li>Put eyes of ender into the end portal to activate it</li>
                <li>Defeat the ender dragon</li>
              </ol>
            </DocumentSection>
            <DocumentSection v-model="navigationGeneral" title="Navigation - General">
              <p>Describe key locations, landmarks, and the guidance provided to the player to traverse the space.</p>

              <p class="example">Example:</p>
              <ul class="example">
                <li>Gold brick blocks show the most direct path in any given area</li>
                <li>Whenever you see a red flag that means you are near a quest giver</li>
                <li>Green pluses are visible on locations with healing kits in them (56,80,200), (333,70,80),
                  (676,70,400),
                  (176,80,230), (-176,70,-600)
                </li>
                <li>The player starts with a GPS item that when used will have an arrow appear pointing to the next
                  player
                  objective of their current quest
                </li>
              </ul>
            </DocumentSection>
            <DocumentSection v-model="navigationKeyLocations" title="Navigation - Key Locations">
              <p class="example">Example:</p>
              <ul class="example">
                <li>The blue building the player starts in is the hub at the center of the city (0,70,0)</li>
                <li>The hospital to heal the player is 200 blocks south of the hub and has a large green plus on it
                  (0,70,-250)
                  <ul>
                    <li>The player can also get health upgrades here</li>
                  </ul>
                </li>
                <li>To the right of the hub is a subway station that leads to fast travel buttons under the surface of
                  the
                  city (150,70,0)
                </li>
              </ul>
            </DocumentSection>
            <DocumentSection v-model="navigationLandmarks" title="Navigation - Landmarks">
              <p class="example">Example:</p>
              <ul class="example">
                <li>Giant statues are above each boss fight on the map (156,73,300), (233,90,100), (566,40,670)</li>
                <li>The hub is a massive building at the center of the city to help the player orientate themselves
                  (0,70,0)
                </li>
                <li>The Smokey Mountain has hidden treasure for players that like to explore (3000, 128, -1500)</li>
              </ul>
            </DocumentSection>
            <DocumentSection v-model="characters" title="Characters">
              <p>Highlight all NPCs that exist in the content (any and all relevant, important features the NPCs provide
                to
                the player) or the progress of the story.</p>
              <p class="example">Example:</p>
              <ul class="example">
                <li>Farmer Joe
                  <ul>
                    <li>Lives in the brick house in the center of town</li>
                    <li>Sells golden potatoes for the quest “Russet Living”</li>
                  </ul>
                </li>
                <li>Fairy Master
                  <ul>
                    <li>Gives hit to the player for puzzles once every minute if the player interacts with them</li>
                  </ul>
                </li>
                <li>King Omen
                  <ul>
                    <li>Gives the quest to hunt the dragon</li>
                    <li>Must be spoken to in order to unlock leaving the tutorial area</li>
                    <li>When the quest is turned in to them the player is victorious and can be teleported to a vanilla
                      Minecraft world when they talk to the king
                    </li>
                  </ul>
                </li>
              </ul>
            </DocumentSection>
            <DocumentSection v-model="villagers" title="Villagers">
              <p>Highlight all NPCs that exist in the content (any and all relevant, important features the NPCs provide
                to
                the player) or the progress of the story.</p>
              <p class="example">Example:</p>
              <ul class="example">
                <li>Farmer Joe
                  <ul>
                    <li>Lives in the brick house in the center of town</li>
                    <li>Sells golden potatoes for the quest “Russet Living”</li>
                  </ul>
                </li>
                <li>Fairy Master
                  <ul>
                    <li>Gives hit to the player for puzzles once every minute if the player interacts with them</li>
                  </ul>
                </li>
                <li>King Omen
                  <ul>
                    <li>Gives the quest to hunt the dragon</li>
                    <li>Must be spoken to in order to unlock leaving the tutorial area</li>
                    <li>When the quest is turned in to them the player is victorious and can be teleported to a vanilla
                      Minecraft world when they talk to the king
                    </li>
                  </ul>
                </li>
              </ul>
            </DocumentSection>
            <DocumentSection v-model="mechanics" title="Mechanics">
              <p>Gameplay mechanics encompass anything the player can do. Anything unique about your gameplay experience
                should be outlined here.</p>
              <p class="example">Example:</p>
              <ul class="example">
                <li>World destruction when driving trucks</li>
                <li>Checkpoints at red flag that the player will respawn to</li>
                <li>Clues can be collected (marked on screen with UI)</li>
              </ul>
            </DocumentSection>
            <DocumentSection v-model="systems" title="Systems">
              <p>Systems are any external interactions or rules that the player can interact with. Anything unique to
                your
                entities’ or world’s behavior should be outlined here.</p>
              <p class="example">Example:</p>
              <ul class="example">
                <li>Quests are tracked and completed when criteria are met</li>
                <li>Lucky blocks can drop a complex array of items based on weighted values
                  <ul>
                    <li>10 – leather</li>
                    <li>30 – Iron Bars spawn around the player</li>
                    <li>2 – Anvil drops</li>
                    <li>45 – 10 diamonds drop</li>
                    <li>1000 – 3 Jeb sheep</li>
                  </ul>
                </li>
              </ul>
            </DocumentSection>
            <DocumentSection v-model="vanillaChanges" title="Vanilla Changes">
              <p>If any element of your content changes the way something in the vanilla game functions, it should be
                outlined here.</p>
              <p class="example">Examples:</p>
              <ul class="example">
                <li>Made the player jump height double</li>
                <li>Cows always drop 4 leather</li>
                <li>Water now does damage over time when the player is submerged</li>
                <li>Lava flows upwards into the sky</li>
                <li>The ender dragon has a neat hat</li>
              </ul>
            </DocumentSection>
            <DocumentSection v-model="customItems" title="Custom Items">
              <p>List all custom items created for your content including their item ID that would function with
                commands.</p>
              <p class="example">Example:</p>
              <ul class="example">
                <li>Emerald shovel (custom_BP:emerald_shovel)
                  <ul>
                    <li>Has double the durability of diamond but is as efficient as iron</li>
                  </ul>
                </li>
                <li>Diamond chainmail armor (custom_BP:diamond_chainmail_chestplate)</li>
                <li>Razor cat spawn egg (custom_BP:egg_razorcat)</li>
                <li>Lambas Bread (custom_BP:lambas)
                  <ul>
                    <li>Fully fills hunger and food saturation</li>
                  </ul>
                </li>
              </ul>
            </DocumentSection>
            <DocumentSection v-model="customEntities" title="Custom Entities">
              <p>List all custom entities created for your content including their entity ID that would function with
                commands.</p>
              <p class="example">Example:</p>
              <ul class="example">
                <li>Character Joe (custom_BP:joe_villager)</li>
                <li>Apple 3d model (custom_BP:3d_apple)</li>
                <li>Lambo (custom_BP:Racecar)</li>
                <li>Leather recliner (custom_BP:leather_couch_single)</li>
              </ul>
            </DocumentSection>
            <DocumentSection v-model="customSounds" title="Custom Sounds">
              <p>List all custom sounds created for your content including their sound ID that would function with
                commands.</p>
              <p class="example">Example:</p>
              <ul class="example">
                <li>Lucky block break (custom_BP:lucky_block_break)</li>
                <li>Lucky block activate (custom_BP:lucky_block_activate)</li>
                <li>Player jumping on lucky block sound (custom_BP:land_on_lucky_block)</li>
                <li>Goat scream variant 1 (custom_BP:vanilla_goat_scream_1)</li>
                <li>Goat scream variant 2 (custom_BP:vanilla_goat_scream_2)</li>
              </ul>
            </DocumentSection>
          </div>
          <div v-show="preview">
            <DocumentPreview :document="revision"/>
          </div>
          <input type="file" @change="onFileChange" ref="fileInput" style="display: none"/>
        </div>
      </div>
    </Page>
  </div>
</template>

<style>
.example {
  font-style: italic;
}
</style>

<script>

import DocumentSection from "@/component/DocumentSection";
import Page from "@/page/Page";
import api from "@/api";
import DocumentDTO from "@/model/DocumentDTO";
import RevisionDTO from "@/model/RevisionDTO";
import NameGenerator from "@/utils/NameGenerator";
import DocumentPreview from "@/component/DocumentPreview";
import unpack from "@/unpacker/unpacker";
import MinecraftColorParser from "@/unpacker/MinecraftColorParser";
import BrandingDTO from "@/model/BrandingDTO";

export default {
  components: {DocumentPreview, DocumentSection, Page},
  name: 'Edit',
  data() {
    return {
      document: new DocumentDTO(),
      revision: new RevisionDTO(),
      originalRevision: new RevisionDTO(),
      steps: [],
      navigationGeneral: [],
      navigationKeyLocations: [],
      navigationLandmarks: [],
      characters: [],
      villagers: [],
      mechanics: [],
      systems: [],
      vanillaChanges: [],
      customItems: [],
      customEntities: [],
      customSounds: [],
      preview: false,
      edit: false,
      brandings: [],
    }
  },
  mounted() {
    this.loadDocument();
  },
  computed: {
    revisions() {
      return this.document ? this.document.revisions : [];
    }
  },
  created() {
    window.onbeforeunload = this.onBeforeUnload;
  },
  destroyed() {
    window.onbeforeunload = null;
  },
  methods: {
    clearCache() {
      if (!this.edit || !this.document) {
        return;
      }
      this.$refs.panel.executeRequest(api.forceChangelogUpdate(this.document.id)).then(() => {
        this.$buefy.toast.open({
          message: 'Cache cleared',
          type: 'is-success',
          duration: 3000
        });
      });
    },
    onBeforeUnload() {
      if (this.hasUnsavedChanges()) {
        return "You have unsaved changes. Are you sure you want to leave?";
      }
    },
    hasUnsavedChanges() {
      return JSON.stringify(this.revision) !== JSON.stringify(this.originalRevision);
    },
    canChangeRevision() {
      return new Promise((resolve) => {
        if (this.hasUnsavedChanges()) {
          this.$buefy.dialog.confirm({
            title: 'Unsaved Changes',
            message: 'You have unsaved changes. Are you sure you want to discard them?',
            confirmText: 'Discard',
            cancelText: 'Cancel',
            type: 'is-danger',
            hasIcon: true,
            icon: 'fa-exclamation-triangle',
            onConfirm: () => {
              resolve(true);
            },
            onCancel: () => {
              resolve(false);
            }
          });
        } else {
          resolve(true);
        }
      });
    },
    deleteRevision(id) {
      this.$refs.panel.executeRequest(api.deleteRevision(this.document.id, id)).then(() => {
        if (this.revision.id === id) {
          this.setRevision(this.document.currentRevision);
        }
        this.document.revisions = this.document.revisions.filter(r => r.id !== id);
      });
    },
    setPublic(value) {
      if (value === this.document.isPublic) {
        return;
      }
      this.$refs.panel.executeRequest(api.setIsDocumentPublic(this.document.id, value)).then(() => {
        this.document.isPublic = value;
      });
    },
    setActive(id) {
      this.$refs.panel.executeRequest(api.setActiveRevision(this.document.id, id)).then((revision) => {
        this.revision.isActive = this.revision.id === revision.id;
        this.document.currentRevision = new RevisionDTO(revision, true);
      });
    },
    showRevision(id) {
      if (id === this.revision.id) {
        return;
      }
      this.canChangeRevision().then(canChange => {
        if (canChange) {
          this.$refs.panel.executeRequest(api.getRevisionById(this.document.id, id)).then((revision) => {
            this.setRevision(new RevisionDTO(revision, revision.id === this.document.currentRevision.id));
            this.setupUI();
          });
        }
      });
    },
    save() {
      if (this.revision.name === '') {
        this.$buefy.snackbar.open({
          message: 'Name cannot be empty!',
          type: 'is-danger',
          position: 'is-top-right',
          queue: false,
          duration: 3000
        });
        return;
      }
      if (!this.revision.branding.id) {
        this.$buefy.snackbar.open({
          message: 'Branding cannot be empty!',
          type: 'is-danger',
          position: 'is-top-right',
          queue: false,
          duration: 3000
        });
        return;
      }
      if (this.edit) {
        this.$refs.panel.executeRequest(api.createRevision(this.document.id, this.revision)).then((revision) => {
          if (revision.id === this.revision.id) {
            this.revision.isActive = true;
            this.document.currentRevision = this.revision;
          } else if (this.document.revisions.some(r => r.id === revision.id)) {
            this.setRevision(new RevisionDTO(revision, true));
            this.document.currentRevision = this.revision;
          } else {
            this.setRevision(new RevisionDTO(revision, true));
            this.document.currentRevision = this.revision;
            this.document.revisions.unshift(this.revision);
          }
          api.setDocumentRepo(this.document.id, this.document.repo);
        });
      } else {
        this.$refs.panel.executeRequest(api.createDocument(this.revision)).then((document) => {
          let repo = this.document.repo;
          api.setDocumentRepo(document.id, repo);
          this.document = new DocumentDTO(document);
          this.document.repo = repo;
          this.setRevision(this.document.currentRevision);
          this.edit = true;
          this.$router.replace({params: {id: this.document.id}});
        });
      }
    },
    loadDocument() {
      if (this.$route.params.id) {
        this.$refs.panel.executeRequest(
            api.listBranding().then((brandings) => {
              this.brandings = brandings.data.map(b => new BrandingDTO(b));
              return api.getDocumentById(this.$route.params.id);
            })
        ).then((document) => {
          this.document = new DocumentDTO(document);
          this.setRevision(this.document.currentRevision);
          this.edit = true;
          this.setupUI();
        });
      } else {
        this.$refs.panel.executeRequest(api.listBranding()).then((brandings) => {
          this.brandings = brandings.map(b => new BrandingDTO(b));
          this.revision.name = NameGenerator.generate();
          this.revision.branding.id = this.brandings[0].id;
          this.edit = false;
          this.setupUI();
        });
      }
    },
    loadPack() {
      if (this.revision.elements.customItems.length !== 0 || this.revision.elements.customEntities.length !== 0 || this.revision.elements.customSounds.length !== 0) {
        this.$buefy.dialog.confirm({
          title: 'Importing pack',
          message: 'Importing pack will overwrite your current content (Custom Items, Custom Entities and Custom Sounds).<br><b>Are you sure you want to continue?</b>',
          confirmText: 'Import pack',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => this.openFilePicker()
        })
      } else {
        this.openFilePicker();
      }
    },
    openFilePicker() {
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      this.$refs.panel.setLoading(true);
      let file = e.target.files[0];
      unpack(file).then((addon) => {
        this.addon = addon;
        this.$refs.panel.setLoading(false);
        if (this.addon.isEmpty()) {
          this.$buefy.toast.open({
            message: 'No assets found in the pack',
            type: 'is-warning',
          });
        } else {
          if (addon.items.length !== 0) {
            this.customItems = [];
            let i = 1;
            addon.items.forEach(item => {
              this.customItems.push({
                content: MinecraftColorParser.parse(item.name) + ' (' + item.id + ')',
                order: i++
              });
            });
          }
          if (addon.entities.length !== 0) {
            this.customEntities = [];
            let i = 1;
            addon.entities.forEach(item => {
              if (!item.id.startsWith('minecraft:')) {
                this.customEntities.push({
                  content: MinecraftColorParser.parse(item.name) + ' (' + item.id + ')',
                  order: i++
                });
              }
            });
          }
          this.$buefy.toast.open({
            message: 'Pack loaded successfully',
            type: 'is-success',
          });
        }
      }).catch(err => {
        console.log(err)
        this.$refs.panel.setLoading(false);
        this.$refs.panel.setError('Failed to parse the pack: ' + err.message);
      });
    },
    setupUI() {
      this.steps = this.revision.elements.steps.map((content, index) => {
        return {content, order: index + 1};
      });
      this.navigationGeneral = this.revision.elements.general.map((content, index) => {
        return {content, order: index + 1};
      });
      this.navigationKeyLocations = this.revision.elements.keyLocations.map((content, index) => {
        return {content, order: index + 1};
      });
      this.navigationLandmarks = this.revision.elements.landmarks.map((content, index) => {
        return {content, order: index + 1};
      });
      this.characters = this.revision.elements.characters.map((content, index) => {
        return {content, order: index + 1};
      });
      this.villagers = this.revision.elements.villagers.map((content, index) => {
        return {content, order: index + 1};
      });
      this.mechanics = this.revision.elements.mechanics.map((content, index) => {
        return {content, order: index + 1};
      });
      this.systems = this.revision.elements.systems.map((content, index) => {
        return {content, order: index + 1};
      });
      this.vanillaChanges = this.revision.elements.vanillaChanges.map((content, index) => {
        return {content, order: index + 1};
      });
      this.customItems = this.revision.elements.customItems.map((content, index) => {
        return {content, order: index + 1};
      });
      this.customEntities = this.revision.elements.customEntities.map((content, index) => {
        return {content, order: index + 1};
      });
      this.customSounds = this.revision.elements.customSounds.map((content, index) => {
        return {content, order: index + 1};
      });
    },
    setRevision(revision) {
      revision.branding = new BrandingDTO(this.brandings.find(b => b.id === revision.branding.id).toJson());
      this.revision = revision;
      this.originalRevision = new RevisionDTO(this.revision.toJson(), revision.isActive);
    },
  },
  watch: {
    $route(next, prev) {
      if (prev.params.id !== next.params.id && next.name === 'edit' && prev.params.id !== void 0 && next.params.id !== void 0) {
        this.loadDocument();
      }
      if (prev.params.id !== next.params.id && next.name === 'edit' && next.params.id === void 0) {
        this.canChangeRevision().then(canChange => {
          if (canChange) {
            this.document = new DocumentDTO();
            this.setRevision(new RevisionDTO());
            this.loadDocument();
          } else {
            this.$router.replace({name: 'edit', params: {id: prev.params.id}});
          }
        });
      }
    },
    steps: {
      handler(v) {
        this.revision.elements.steps = v.map(e => e.content);
      },
      deep: true
    },
    navigationGeneral: {
      handler(v) {
        this.revision.elements.general = v.map(e => e.content);
      },
      deep: true
    },
    navigationKeyLocations: {
      handler(v) {
        this.revision.elements.keyLocations = v.map(e => e.content);
      },
      deep: true
    },
    navigationLandmarks: {
      handler(v) {
        this.revision.elements.landmarks = v.map(e => e.content);
      },
      deep: true
    },
    characters: {
      handler(v) {
        this.revision.elements.characters = v.map(e => e.content);
      },
      deep: true
    },
    villagers: {
      handler(v) {
        this.revision.elements.villagers = v.map(e => e.content);
      },
      deep: true
    },
    mechanics: {
      handler(v) {
        this.revision.elements.mechanics = v.map(e => e.content);
      },
      deep: true
    },
    systems: {
      handler(v) {
        this.revision.elements.systems = v.map(e => e.content);
      },
      deep: true
    },
    vanillaChanges: {
      handler(v) {
        this.revision.elements.vanillaChanges = v.map(e => e.content);
      },
      deep: true
    },
    customItems: {
      handler(v) {
        this.revision.elements.customItems = v.map(e => e.content);
      },
      deep: true
    },
    customEntities: {
      handler(v) {
        this.revision.elements.customEntities = v.map(e => e.content);
      },
      deep: true
    },
    customSounds: {
      handler(v) {
        this.revision.elements.customSounds = v.map(e => e.content);
      },
      deep: true
    }
  },
}
</script>