<template>
  <section class="hero is-primary is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <p class="title">
          cr-tool
        </p>
        <p class="subtitle">
          <a @click="login">Click here to log in</a><br/>
          <a v-if="isDev" @click="impersonate">Impersonate</a>
        </p>
      </div>
    </div>
    <b-loading is-full-page v-model="isLoading"></b-loading>
  </section>
</template>

<style>
</style>

<script>

import api from '../api';
import UserDTO from "@/model/UserDTO.ts";

export default {
  components: {},
  name: 'App',
  data() {
    return {
      isLoading: true,
      loginUrl: '',
      isDev: window.webpackHotUpdate
    }
  },
  mounted() {
    window.api = api;
    api.getUser().then((data) => {
      this.isLoading = false;
      if (data.success) {
        this.$store.commit('user', new UserDTO(data.data));
        if (this.$route.query.next) {
          this.$router.push(this.$route.query.next);
        } else {
          this.$router.push('/browse');
        }
      } else {
        this.loginUrl = data.redirectUrl;
      }
    });
  },
  methods: {
    login() {
      this.isLoading = true;
      const win = window.open(this.loginUrl, '_blank');
      const timer = setInterval(() => {
        try {
          if (win.location.domain === window.location.domain && win.location.href.indexOf('/logged') > -1) {
            clearInterval(timer);
            win.close();
            api.getUser().then((data) => {
              this.isLoading = false;
              if (data.success) {
                this.$store.commit('user', new UserDTO(data.data));
                if (this.$route.query.next) {
                  this.$router.push(this.$route.query.next);
                } else {
                  this.$router.push('/browse');
                }
              }
            });
            return;
          }
        } catch (ignored) {
          // Ignore access errors
        }
        if (win.closed) {
          clearInterval(timer);
          api.getUser().then((data) => {
            this.isLoading = false;
            if (data.success) {
              this.$store.commit('user', new UserDTO(data.data));
                if (this.$route.query.next) {
                  this.$router.push(this.$route.query.next);
                } else {
                  this.$router.push('/browse');
                }
            }
          });
        }
      }, 500);
    },
    impersonate() {
      this.isLoading = true;
      const win = window.open('http://localhost/dev/impersonate?email=piotr@ascentbv.com', '_blank');
      const timer = setInterval(() => {
        try {
          if (win.location.href.indexOf('/logged') > -1) {
            clearInterval(timer);
            win.close();
            api.getUser().then((data) => {
              this.isLoading = false;
              if (data.success) {
                this.$store.commit('user', data.data);
                if (this.$route.query.next) {
                  this.$router.push(this.$route.query.next);
                } else {
                  this.$router.push('/browse');
                }
              }
            });
            return;
          }
        } catch (ignored) {
          // Ignore access errors
        }
        if (win.closed) {
          clearInterval(timer);
          api.getUser().then((data) => {
            this.isLoading = false;
            if (data.success) {
              this.$store.commit('user', data.data);
                if (this.$route.query.next) {
                  this.$router.push(this.$route.query.next);
                } else {
                  this.$router.push('/browse');
                }
            }
          });
        }
      }, 500);
    }
  }
}
</script>