<template>
  <div>
    <b-navbar class="is-primary">
      <template #brand>
        <b-navbar-item href="#" @click="$router.push({name: 'browse'}).catch(() => {})">
          <h1 style="font-weight: lighter; font-size: 1.5em">cr-tool</h1>
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item href="#" @click="$router.push({name: 'browse'}).catch(() => {})">
          Browse
        </b-navbar-item>
        <b-navbar-item href="#" @click="$router.push({name: 'branding_list'}).catch(() => {})">
          Branding
        </b-navbar-item>
        <b-navbar-item href="#" @click="$router.push({name: 'edit'}).catch(() => {})">
          Create
        </b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item tag="div">
          <b-button @click="logout" icon-left="logout">
            Logout
          </b-button>
        </b-navbar-item>
      </template>
    </b-navbar>
    <div style="padding: 1em 1em">
      <b-notification
          has-icon
          v-if="error"
          type="is-danger"
          aria-close-label="Close notification"
          @close="error = null"
          role="alert">
        {{ error }}
      </b-notification>
      <slot></slot>
    </div>
    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          <span style="font-weight: lighter">cr-tool</span> by ASCENT. All rights reserved.
        </p>
      </div>
    </footer>
    <b-loading is-full-page v-model="loading"/>
  </div>
</template>

<style>
</style>

<script>


import api from "@/api";
import store from "@/store";

export default {
  components: {},
  name: 'Page',
  data() {
    return {
      error: null,
      loading: false,
    }
  },
  methods: {
    logout() {
      api.logout().then(() => {
        store.commit('no_user');
        this.$router.replace('/')
      }).catch(error => {
        this.error = error;
      });
    },
    setLoading(loading) {
      this.error = null;
      this.loading = loading;
    },
    setError(error) {
      this.error = error;
    },
    executeRequest(promise) {
      this.setLoading(true);
      return promise.then((response) => {
        this.setLoading(false);
        if (!response.success) {
          this.setError(response.errorMessage);
        } else {
          return response.data;
        }
      }).catch(error => {
        this.setLoading(false);
        this.setError(error);
      });
    }
  }
}
</script>