import LangFile from "@/unpacker/langFile";
import JSZip from "jszip";
import * as Hjson from "hjson";
import Pack from "@/unpacker/pack";
import Addon from "@/unpacker/addon";
import File from "@/unpacker/file";

const unpack = function (f) {
    const files = [];
    let read = new JSZip();
    return read.loadAsync(f).then(function (zip) {
        return zip.files;
    }).then(function (entries) {
        let promises = [];
        let keys = Object.keys(entries);
        for (let i = 0; i < keys.length; i++) {
            const file = entries[keys[i]];
            if (file.dir) {
                continue;
            }
            if (keys[i].endsWith(".lang")) {
                promises.push(file.async('blob').then(async function (data) {
                    files.push(new File(keys[i], LangFile.parse(await data.text()), data));
                }));
            } else if (keys[i].endsWith(".json")) {
                promises.push(file.async('blob').then(async function (data) {
                    files.push(new File(keys[i], Hjson.parse(await data.text()), data));
                }));
            }
        }
        return Promise.all(promises);
    }).then(function () {
        const packs = [];
        for (const file of files) {
            if (file.path.endsWith("manifest.json")) {
                packs.push(new Pack(file, files));
            }
        }
        const bp = packs.find(function (pack) {
            return pack.type === Pack.BEHAVIOR_PACK;
        });
        const rp = packs.find(function (pack) {
            return pack.type === Pack.RESOURCE_PACK;
        });
        return new Addon(bp, rp);
    });
}

export default unpack;