<template>
  <div id="document-preview" class="document-preview">
    <h1 id="content-guide">Content Guide</h1>
    <table>
      <tr>
        <td>Content Name</td>
        <td>{{ document.name }}</td>
      </tr>
      <tr>
        <td>Creator Team Name</td>
        <td>{{ document.branding.teamName }}</td>
      </tr>
      <tr>
        <td>Date</td>
        <td>{{ new Date(document.date).toLocaleString() }}</td>
      </tr>
    </table>

    <h2 id="design">Design</h2>
    <h3>Playthrough Guide</h3>
    <table>
      <tr>
        <td>Steps</td>
        <td>
          <ol>
            <li v-for="(e, i) in document.elements.steps" v-bind:key="i" v-html="renderMarkdown(e)">
            </li>
          </ol>
        </td>
      </tr>
    </table>

    <h3>Navigation</h3>
    <table>
      <tr>
        <td>General</td>
        <td>
          <ul>
            <li v-for="(e, i) in document.elements.general" v-bind:key="i" v-html="renderMarkdown(e)">
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Key Locations</td>
        <td>
          <ul>
            <li v-for="(e, i) in document.elements.keyLocations" v-bind:key="i" v-html="renderMarkdown(e)">
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Landmarks</td>
        <td>
          <ul>
            <li v-for="(e, i) in document.elements.landmarks" v-bind:key="i" v-html="renderMarkdown(e)">
            </li>
          </ul>
        </td>
      </tr>
    </table>

    <h3>Characters and Villagers</h3>
    <table>
      <tr>
        <td>Characters</td>
        <td>
          <ul>
            <li v-for="(e, i) in document.elements.characters" v-bind:key="i" v-html="renderMarkdown(e)">
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Villagers</td>
        <td>
          <ul>
            <li v-for="(e, i) in document.elements.villagers" v-bind:key="i" v-html="renderMarkdown(e)">
            </li>
          </ul>
        </td>
      </tr>
    </table>

    <h3>Mechanics and Systems</h3>
    <table>
      <tr>
        <td>Mechanics</td>
        <td>
          <ul>
            <li v-for="(e, i) in document.elements.mechanics" v-bind:key="i" v-html="renderMarkdown(e)">
            </li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Systems</td>
        <td>
          <ul>
            <li v-for="(e, i) in document.elements.systems" v-bind:key="i" v-html="renderMarkdown(e)">
            </li>
          </ul>
        </td>
      </tr>
    </table>

    <h2 id="technical-elements">Technical Elements</h2>
    <h3>Vanilla Behavior Edits</h3>
    <table>
      <tr>
        <td>Vanilla Changes</td>
        <td>
          <ul>
            <li v-for="(e, i) in document.elements.vanillaChanges" v-bind:key="i" v-html="renderMarkdown(e)">
            </li>
          </ul>
        </td>
      </tr>
    </table>

    <h3>Inventory Items</h3>
    <table>
      <tr>
        <td>Custom Items</td>
        <td>
          <ul>
            <li v-for="(e, i) in document.elements.customItems" v-bind:key="i" v-html="renderMarkdown(e)">
            </li>
          </ul>
        </td>
      </tr>
    </table>

    <h3>Custom Creatures, Entities, and Decorations</h3>
    <table>
      <tr>
        <td>Custom Entities</td>
        <td>
          <ul>
            <li v-for="(e, i) in document.elements.customEntities" v-bind:key="i" v-html="renderMarkdown(e)">
            </li>
          </ul>
        </td>
      </tr>
    </table>

    <h3>Custom Sounds</h3>
    <table>
      <tr>
        <td>Custom Sounds</td>
        <td>
          <ul>
            <li v-for="(e, i) in document.elements.customSounds" v-bind:key="i" v-html="renderMarkdown(e)">
            </li>
          </ul>
        </td>
      </tr>
    </table>

    <div v-if="document && document.changelog">
    <h1>Changelog</h1>
      <div v-for="(e, i) in document.changelog" v-bind:key="i" :id="toId(e.id)">
        <h2>{{ e.id }}</h2>
        <h3 v-if="e.name && e.name !== e.id">{{ e.name }}</h3>
        <div v-html="renderMarkdown(e.content)"></div>
      </div>
    </div>

  </div>
</template>

<script>

import {createMarkdown} from "safe-marked";
import PublicDocumentDTO from "@/model/PublicDocumentDTO";
import RevisionDTO from "@/model/RevisionDTO";

const markdown = createMarkdown();

export default {
  name: "DocumentPreview",
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
    //TODO: Move it to util class
    toId(text) {
      return text.replace(/[^a-zA-Z\d]/g, '-').toLowerCase();
    },
    renderMarkdown(text) {
      return markdown(text);
    }
  },
  props: {
    document: {
      type: [PublicDocumentDTO, RevisionDTO]
    }
  }
}
</script>

<style scoped>

h1 {
  font-size: 1.5em;
  margin: 0.5em 0;
  font-weight: bold;
}

h1:not(:first-of-type), h2:not(:first-of-type) {
  page-break-before: always !important;
}

table {
  page-break-inside: avoid !important;
}

h2 {
  font-size: 1.2em;
  margin: 0.5em 0;
  font-weight: bold;
}

h3 {
  font-size: 1.1em;
  margin: 0.5em 0;
}
</style>