<template>
  <section class="hero is-success is-fullheight">
    <div class="hero-body">
      <div class="container has-text-centered">
        <p class="title">
          Logged in as {{ user.name }}
        </p>
        <p class="subtitle">
          You can close this window.
        </p>
      </div>
    </div>
  </section>
</template>

<style>

</style>

<script>

import api from '../api'
import UserDTO from "@/model/UserDTO";

export default {
  components: {},
  data: function () {
    return {
      user: new UserDTO()
    }
  },
  mounted() {
    if (!this.$store.state.user) {
      api.getUser().then(u => {
        if (u.success) {
          this.user = u.data
          this.$store.commit('user', new UserDTO(this.user))
        }
      })
    } else {
      this.user = this.$store.state.user;
    }
  },
  name: 'App',
}
</script>