<template>
  <div class="card content">
    <div class="card-content">
      <div class="content">
        <b-field grouped>
          <b-icon icon="drag-horizontal-variant" class="handle"></b-icon>
          <b-input v-model="text" placeholder="Content" @input="$emit('input', text)" expanded :type="multiline ? 'textarea': 'text'"></b-input>
          <b-button v-if="!multiline" @click="multiline = true">Expand</b-button>
          <b-button icon-left="delete" @click="$emit('remove')" class="is-danger"
                    style="margin-top: auto;margin-bottom: auto;margin-left:0.5em"></b-button>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "SectionElement",
  data() {
    return {
      text: "",
      multiline: false
    }
  },
  mounted() {
    this.text = this.value;
    this.multiline = this.value.indexOf("\n") > -1;
  },
  watch: {
    value(newValue) {
      this.text = newValue;
      this.multiline = newValue.indexOf('\n') > -1;
    }
  },
  methods: {},
  props: {
    value: {
      type: String
    }
  }
}
</script>

<style scoped>
.handle {
  cursor: move;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1.5em;
}
</style>