import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/page/Login'
import api from "@/api";
import store from '@/store'
import LoggedIn from "@/page/LoggedIn";
import Browse from "@/page/Browse";
import View from "@/page/DocumentView";
import Edit from "@/page/Edit";
import BrandingList from "@/page/BrandingList";
import BrandingEdit from "@/page/BrandingEdit";

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {path: '/', component: Login},
        {path: '/logged', name: 'logged', component: LoggedIn},
        {
            path: '/browse',
            name: 'browse',
            component: Browse,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/edit/:id?',
            name: 'edit',
            component: Edit,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/branding',
            name: 'branding_list',
            component: BrandingList,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/branding/:id?',
            name: 'branding_edit',
            component: BrandingEdit,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/view/:id',
            name: 'view',
            component: View
        },

        {path: '*', redirect: '/'}
    ]
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.getUser) {
            next();
            return;
        }
        api.getUser()
            .then(response => {
                if (response.errorMessage) {
                    store.commit("no_user")
                    next({
                        path: '/login?next=' + to.path
                    });
                } else {
                    store.commit("user", response.data)
                    next();
                }
            })
            .catch(() => {
                store.commit("no_user")
                next({
                    path: '/login?next=' + to.path
                });
            })
    } else {
        next();
    }
});

export default router;