<template>
  <Page ref="panel">
    <div class="container">
      <div class="is-flex is-flex-wrap-wrap">
        <DocumentCard v-for="doc in documents" v-bind:key="doc.id" class="column is-one-quarter" :document="doc">
        </DocumentCard>
      </div>
    </div>
  </Page>
</template>

<style>
</style>

<script>


import Page from "@/page/Page";
import api from "@/api";
import DocumentCard from "@/component/DocumentCard";
import DocumentDTO from "@/model/DocumentDTO";

export default {
  components: {DocumentCard, Page},
  name: 'Browse',
  data() {
    return {
      documents: [],
      lastDoc: -1,
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    loadDocuments() {
      if (this.lastDoc === -2) {
        return;
      }
      this.$refs.panel.executeRequest(api.listDocuments(this.lastDoc)).then(response => {
        this.documents = this.documents.concat(response.content.map(x => new DocumentDTO(x)));
        this.lastDoc = response.lastElement;
      });
    },
    handleScroll() {
      if (document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight) {
        this.loadDocuments();
      }
    },
  },
  mounted() {
    this.loadDocuments();
  },
}
</script>