const filesInDir = (files, dir) => {
    return files.filter(file => file.path.startsWith(dir));
}

const haveCommonItems = (a, b) => {
    return a.some(item => b.includes(item));
}

const isArray = (obj) => {
    return Array.isArray(obj) || Object.prototype.toString.call(obj) === '[object Array]';
}

const isObject = (obj) => {
    return obj !== null && typeof obj === 'object';
}

const isString = (obj) => {
    return typeof obj === 'string' || obj instanceof String;
}

export {filesInDir, haveCommonItems, isArray, isObject, isString};