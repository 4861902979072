<template>
  <div class="card content">
    <div class="card-content">
      <div class="content">
        <p class="title">
          <a @click="collapsed = !collapsed">{{ title }}</a>
          <b-button icon-right="help" @click="showHelp = !showHelp" class="is-pulled-right"></b-button>
        </p>
        <div v-if="showHelp" style="margin-bottom: 1em;">
          <slot></slot>
        </div>
        <div v-if="!collapsed">
          <draggable
              class="list-group"
              v-model="elements"
              v-bind="dragOptions"
              @start="dragStart"
              @end="dragEnd"
              @change="listChange"
              handle=".handle"
          >
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
              <SectionElement
                  class="list-group-item"
                  v-for="element in elements"
                  :key="element.order"
                  @change="listChange"
                  v-model="element.content"
                  v-on:remove="remove(element)">
              </SectionElement>
            </transition-group>
            <div
                slot="footer"
                class="btn-group list-group-item"
                role="group"
                aria-label="Basic example"
                key="footer"
            >
              <b-button class="btn btn-secondary" @click="add">Add</b-button>
            </div>
          </draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import draggable from "vuedraggable";
import SectionElement from "@/component/SectionElement";
import Vue from "vue";

export default {
  components: {SectionElement, draggable},
  name: "DocumentSection",
  data() {
    return {
      drag: false,
      elements: [],
      collapsed: false,
      showHelp: false
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: this.title,
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: true
    }
  },
  watch: {
    value(newValue) {
      this.elements = newValue;
    }
  },
  methods: {
    dragStart() {
      this.drag = true;
    },
    dragEnd() {
      this.drag = false;
    },
    listChange() {
      this.$emit('input', this.elements);
    },
    remove(element) {
      Vue.delete(this.elements, this.elements.map(x => x.order).indexOf(element.order));
      this.$emit('input', this.elements);
    },
    add() {
      this.elements.push({
        order: this.elements.length,
        content: ""
      });
      this.$emit('input', this.elements);
    }
  }
}
</script>

<style scoped>
h3 {
  font-size: x-large;
  font-weight: bold;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group-item {
  margin-bottom: 0.5em;
}

.list-group {
  min-height: 20px;
}
</style>