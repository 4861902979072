export default class LangFile {

    static CHAT_COLOR_MATCHER = /§[\da-fklmnor]/g;

    static parse(data) {
        const lang = {};
        const lines = data.split('\n');
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            if (line.trim().length === 0 || line.trim().startsWith('#')) {
                continue;
            }
            const parts = line.split('=', 2);
            if (parts.length !== 2) {
                throw new Error('Invalid line: ' + line);
            }
            lang[parts[0]] = parts[1].endsWith('\r') ? parts[1].slice(0, -1) : parts[1];
            if (lang[parts[0]].indexOf(' ##') !== -1 || lang[parts[0]].indexOf('\t##') !== -1) {
                lang[parts[0]] = lang[parts[0]].substring(0, lang[parts[0]].indexOf('##') - 1);
            }
        }
        return lang;
    }

    static stripColorCodes(str) {
        if (str) {
            return str.replaceAll(LangFile.CHAT_COLOR_MATCHER, '');
        }
        return str;
    }

    static toLangFile(lang) {
        const lines = [];
        for (const key in lang) {
            lines.push(key + '=' + lang[key]);
        }
        return lines.join('\n');
    }
}