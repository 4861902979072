<template>
  <div class="document-card">
    <div class="card content">
      <div class="card-content">
        <div class="content">
          <p class="title">
            <a @click="$router.push('/edit/' + document.documentId)">{{ document.currentRevision.name }}</a>
          </p>
          <p class="subtitle">{{ document.currentRevision.teamName }}</p>
          <div>Latest revision: {{ new Date(document.currentRevision.date).toLocaleString() }}</div>
          <div>Latest revision author: {{document.currentRevision.author.name}}</div>
        </div>
      </div>
      <footer class="card-footer">
        <a v-if="document.isPublic" :href="'/view/' + document.id" target="_blank" class="card-footer-item">View</a>
        <span v-else class="card-footer-item">Hidden</span>
        <a @click="$router.push('/edit/' + document.id)" class="card-footer-item">Edit</a>
      </footer>
    </div>
  </div>
</template>

<script>
import DocumentDTO from "@/model/DocumentDTO";

export default {
  name: "DocumentCard",
  data() {
    return {}
  },
  mounted() {
  },
  methods: {},
  props: {
    document: {
      type: DocumentDTO
    }
  }
}
</script>

<style scoped>
.document-card {
  padding: 1em;
}
.title {
  font-size: 1.5em;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card-content {
  flex-grow: 1;
}
</style>